
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AppLayout extends Vue {
  beforeCreate() {
    addEventListener("click", event => this.parentPostMessage(event));
  }

  beforeDestroy() {
    removeEventListener("click", event => this.parentPostMessage(event));
  }

  parentPostMessage(event: MouseEvent) {
    parent.postMessage("click", "*");
  }
}

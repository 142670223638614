import Vue from "vue";
import Vuex from "vuex";

// Modules
import AuthStore from "@/app/modules/auth/store";
import FormStore from "@/app/modules/form/store";
import NewsStore from "@/app/modules/news/store";
import BookingStore from "@/app/modules/booking/store";
import CalendarStore from "@/app/modules/calendar/store";
import OrdersStore from "@/app/modules/orders/store";
import DocumentsStore from "@/app/modules/documents/store";
import WhiteboardStore from "@/app/modules/whiteboard/store";
import MSSNGRtoolStore from "@/app/modules/mssngr-tool/store";
import ServiceStore from "@/app/modules/service/store";
import * as GlobalStore from "@/app/modules/_global/store";
import * as ProfileStore from "@/app/modules/profile/store";

Vue.use(Vuex);

export default new Vuex.Store({
  // `strict` means that we can't modify state directly (only through the mutations)
  strict: process.env.NODE_ENV != "production",

  modules: {
    auth: AuthStore,
    form: FormStore,
    news: NewsStore,
    booking: BookingStore,
    calendar: CalendarStore,
    orders: OrdersStore,
    documents: DocumentsStore,
    posts: WhiteboardStore,
    service: ServiceStore,
    mssngr: MSSNGRtoolStore,
    api: GlobalStore.ApiFetchingStore,
    content: GlobalStore.ContentStore,
    device: GlobalStore.DeviceStore,
    snack: GlobalStore.SnackStore,
    app: GlobalStore.AppStore,
    tags: ProfileStore.PersonalInterestsStore,
    profile: ProfileStore.ProfileStore
  }
});

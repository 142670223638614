export default {
  path: "booking",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "reservations",
      component: () => import("@/app/layouts/FixedContentLayout.vue"),

      children: [
        {
          path: "",
          name: "booking.reservations",
          component: () => import("@/app/modules/booking/pages/Booking.vue")
        },

        {
          path: ":id([0-9]*)/edit",
          name: "booking.reservations.edit",
          component: () => import("@/app/modules/booking/pages/EditReservation.vue"),
          props: route => ({
            id: Number(route.params.id)
          })
        },

        {
          path: ":tile([0-9]*)/schedule",
          name: "booking.reservations.schedule",
          component: () => import("@/app/modules/booking/pages/Schedule.vue"),
          props: route => ({
            tileId: Number(route.params.tile)
          })
        },

        {
          path: ":tile([0-9]*)/create",
          name: "booking.reservations.create",
          component: () => import("@/app/modules/booking/pages/CreateReservation.vue"),
          props: route => ({
            tileId: Number(route.params.tile)
          })
        }
      ]
    }
  ]
};

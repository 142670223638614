import { Action, Module, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class MSSNGRtoolStore extends VuexModule {
  @Action
  token(tile: number) {
    return Api.get(`api/apps/mssngr-tool/token?tile_id=${tile}`);
  }

  @Action
  fetchActivities() {
    return Api.get(`api/apps/mssngr-tool/user/activities`);
  }

  @Action
  cancelActivity(code: string) {
    return Api.patch(`api/apps/mssngr-tool/user/activities/${code}`);
  }
}
